<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

      <div class="container">
        <div class="row mt-4 mb-4">
          <div class="col-md-12 text-center">
            <h3>Your Quote</h3>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>
          <div class="row mt-5">
            <div class="col-md-7">
              <div class="quote-summary">
                  <h4>YOUR QUOTE SUMMARY</h4>

                <div class="row">
                  <div class="col-md-6 offset-6">
                    <ul class="list-group float-right">
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Value of the car : &nbsp;&nbsp;
                        <span >KES. {{ quotation.sumInsured | currency('', 0) }}</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Product:
                        <span >Switch</span>
                      </li>
                      <li class="list-group-item d-flex justify-content-between align-items-center">
                        Cover Period:
                        <span >{{ quote_summary.cover_period }}</span>
                      </li>
                    </ul>
                  </div>
                </div>


                  <h5> {{ quotation.make | capitalize }} {{ quotation.model | capitalize }} {{ quotation.yearOfManufacture  }}</h5>
              </div>

              <div class="shadow-lg mt-3 p-4 mb-5 bg-white rounded">

                <div class="mb-4">
                  <h4>Cover</h4>
                </div>

                <ul>
                  <li style="margin-bottom: 7px;">Cover runs for up to 10,000Kms after which you need to top-up via mobile.</li>
                  <li style="margin-bottom: 7px;">Failure to top-up kilometers on depletion immediately downgrades the cover to 3rd
                    party until the renewal date.</li>
                  <li style="margin-bottom: 7px;">Free Telematics Box must be installed in your vehicle at the start of your policy to track
                    mileage, driving style and collision.</li>
                  <li style="margin-bottom: 7px;">
                    Telematics Box installation and valuation are done professionally and conveniently at a
                    location of your choice.
                  </li>
                  <li style="margin-bottom: 7px;">
                    Includes a Mobile App to track and top-up Kilometers as well as file and track claims
                    progress. TIA Switch is underwritten by Takaful and supported by AiCare.
                  </li>
                  <li style="margin-bottom: 7px;">
                    Note: Failure to have the telematics devise installed and valuation done within the first
                    7 days from inception of the policy will lead to an automatic downgrade of the policy
                    from Comprehensive to third party.
                  </li>
                </ul>
                <!--<div class="mb-2">
                  <h6>Additional Benefits</h6>
                </div>-->


                <!--<div class="form-group mb-3" v-for="benefit in additional_benefits">
                  <label>
                    <input type="checkbox" name="comprehensive" class="check-mark"> &nbsp;&nbsp; {{ benefit }}
                  </label>
                </div>-->
              </div>

              <div class="shadow-lg mt-3 p-4 mb-5 bg-white rounded">

                <div class="mb-4">
                  <h4>What’s Covered</h4>
                </div>

                <ul>
                  <li style="margin-bottom: 7px;">Accidental loss, damage or destruction to the vehicle(s) covered under the policy</li>
                  <li style="margin-bottom: 7px;">Accidental loss or damage to other person’s property arising from usage of the covered
                    vehicle. Property of the participant and family is not covered.</li>
                  <li style="margin-bottom: 7px;">Accidental death or bodily injury to third parties in and outside the vehicle except the
                    incident driver, the participant, employees of the participant and family members.</li>
                  <li style="margin-bottom: 7px;">
                    Legal costs necessarily incurred pursuant to covered incidents involving the vehicle.
                  </li>
                  <li style="margin-bottom: 7px;">
                    Courtesy Uber during vehicle repair following accident, fire or theft. (up to Kes.
                    30,000)
                  </li>
                  <li style="margin-bottom: 7px;">
                    Cost of replacing the windscreen in the event of an accident, or damage for up to Kes.
                    50,000.
                  </li>
                  <li style="margin-bottom: 7px;">
                    Your specified vehicle(s) is/are covered while parked, being services/repaired or in
                    motion within the territorial limits shown on the policy schedule
                  </li>
                </ul>

              </div>

              <div class="shadow-lg mt-3 p-4 mb-5 bg-white rounded">

                <div class="mb-4">
                  <h4>Period of Cover and Contribution Payable</h4>
                </div>

                <ul>
                  <li style="margin-bottom: 7px;">
                    The period of cover is one year (365 days) commencing the purchase date of the policy.</li>

                </ul>

              </div>


              <div class="shadow-lg mt-3 p-4 mb-5 bg-white rounded">

                <div class="mb-4">
                  <h4>Key Exclusions & conditions</h4>
                </div>

                <div>
                  Losses arising out of or in connection with the following are not covered
                </div>

                <ul>
                  <li style="margin-bottom: 7px;">Loss or injury sustained by the participant, family members, employees and driver
                    at the time of the accident.</li>
                  <li style="margin-bottom: 7px;">Deliberate or willful acts by the participant or employees leading to loss, damage or
                    injury.</li>
                  <li style="margin-bottom: 7px;">Any ventures that are harmful to society and are not shariah compliant.</li>
                  <li style="margin-bottom: 7px;">Acts of customs or other lawful or enforcement agencies carrying out official duty.</li>

                </ul>

              </div>


            </div>
            <div class="col-md-5">

              <div class="quote-success">
                <div class="row">
                  <div class="col-md-5">
                    <img src="@/client/assets/images/congragulations.png" class="img img-fluid" />
                  </div>
                  <div class="col-md-7 text-center">
                    <h4>Congratulations</h4>
                    <br/>
                    <p>You qualify for a discount of <br/><br/> <span class="secondary-color">KES. {{(quotation.traditionalPremium - quotation.distancePremium) | currency('', 0)}} </span> </p>
                  </div>
                </div>

              </div>



              <div class="p-4 mb-5 ">
                <div class="mb-4" >
                  <h4  >Quote Summary</h4>
                </div>


                  <table class="table">
                    <tr v-for="item in quote_summary.items">
                        <th style="padding-top: 7px;padding-bottom: 7px;">{{ item.name }}</th>
                        <td>KES. {{ item.value | currency('', 0) }}</td>
                    </tr>
                    <tr>
                      <th>Total </th>
                      <td class="text-danger">KES. {{ quotation.distancePremium | currency('', 0) }}</td>
                    </tr>
                  </table>
              </div>
            </div>
          </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>

        <div class="row mb-5">
          <div class="col-md-3 offset-3">
            <button @click="goBackToHomepage" class="btn btn-lg quote-btn btn-block btn-outline-dark">  Back</button>
          </div>
          <div class="col-md-3">
            <button @click="navigateToCoverWizard" class=" btn  btn-lg  quote-btn quotation-button">  Buy Now </button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
  </div>
</template>

<script>

  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        isLoading: false,
        fullPage: true,
        quotation:{
          quoteId: "",
          modelId: "",
          traditionalPremium: "",
          sumInsured: "",
          make:"",
          model:"",
          yearOfManufacture:"",
          vehicleRegistrationNumber:"",
          distancePremium: ""
        },
        quote_summary:{
          cover_period:"One Year",

          items:[
            {
              name:"Promo Discount",
              value:0
            }
          ],

        },
        additional_benefits:[
                /*"Loss of Use",
                "Terrorism, sabotage & political risks",
                "Excess protector",
                "24 Hour Road Rescue Fees",
                "24 Hour Road Rescue (Infama)"*/
        ]
      };
    },
    computed:{
      total(){

        return this.quote_summary.items.reduce((sum, item) => sum + item.value, 0)
      }
    },
    mounted(){

      //this.quotation = JSON.parse(localStorage.getItem("quote"));



      this.getQuoteData();
    },
    components: {
      Loading
    },

    methods:{
      ...mapActions(["getQuoteDetails"]),

      getQuoteData(){
        let self = this;
        this.isLoading = true;

        this.getQuoteDetails({id: this.$route.params.quoteId}).then((quote) => {
          self.isLoading = false;

          console.log(quote);

          self.quotation.quoteId = quote._id;
          self.quotation.modelId = quote.modelId;
          self.quotation.traditionalPremium = quote.traditionalPremium;
          self.quotation.sumInsured = quote.sumInsured;
          self.quotation.make = self.$route.query.make;
          self.quotation.model = self.$route.query.model;
          self.quotation.yearOfManufacture = quote.yearOfManufacture;
          self.quotation.vehicleRegistrationNumber = quote.vehicleRegistrationNumber;
          self.quotation.distancePremium = quote.distancePremium;

          self.quote_summary.items.splice(0,0 ,{
            name:"Gross Premium",
            value: self.quotation.traditionalPremium
          })

          self.quote_summary.items.splice(self.quote_summary.items.length - 1,0 ,{
            name:"Personalized Discount",
            value: self.quotation.traditionalPremium - self.quotation.distancePremium
          });

          console.log(self.quotation);

          localStorage.setItem("quote",JSON.stringify(
                  {
                    quoteId: quote._id,
                    modelId: quote.modelId,
                    traditionalPremium: quote.traditionalPremium,
                    sumInsured: quote.sumInsured,
                    make:self.$route.query.make,
                    model:self.$route.query.model,
                    yearOfManufacture:quote.yearOfManufacture,
                    vehicleRegistrationNumber:quote.vehicleRegistrationNumber,
                    distancePremium: quote.distancePremium
                  }
          ));


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      },

      goBackToHomepage(){
        location.replace("https://tiaswitch.ke");
      },
      navigateToCoverWizard(){

        this.$router.push('/checkout')
      }
    }
  }
</script>

<style>

  .list-group-item {
    background-color: transparent !important;
    color:#fff !important;
    border:none !important;
  }

  .quote-summary{
    padding: 20px;
    background-color: #2F3641;
    border-radius: 5px;
    color:#fff;
  }

  .quote-summary h4 {
    font-size: 1.2em;
  }

  .quote-summary h4, .quote-summary h5,  .quote-success h4{
    color:#fff;
  }

  .quote-success{
    background-color:#13233A;
    border-radius: 5px;
    padding: 40px 20px;
    color:#fff;
  }

  .quote-btn{
    width: 100%;
  }


</style>
